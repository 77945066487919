<template src="./Loading.html"></template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Loading',
  data: () => ({
    absolute: true
  }),
  computed: {
    ...mapGetters({
      loading: 'getLoading'
    })
  }
}
</script>
