import axios from '../plugins/axios'

const getUser = id => axios.get(`/user/${id}`)
const getAllUsers = search => axios.get('/users', { params: { ...search } })
const createUser = user => axios.post('/user', user)
const deleteUser = id => axios.delete(`/user/${id}`)
const updateUser = user => axios.put('/user', user)
const getRoles = () => axios.get('/user/roles')
const getAnalysts = () => axios.get('/user/analyst')

export {
  getUser,
  createUser,
  getAllUsers,
  deleteUser,
  updateUser,
  getRoles,
  getAnalysts
}
