<template src="./ComplianceByUser.html"></template>

<script>
import {
  tasksByUser,
  getCompliance,
  getWeeklyUser,
  getMonthlyCompliance,
  getAnnualCompliance,
  getUserComplianceCompanies
} from '../../../services'
import { TASK_STATUS } from '../../../utils/constants'
import { mapGetters } from 'vuex'
import { OnlyAdmin, AdminCompliance } from '../../../utils/roles'
import moment from 'moment'
import Companies from '../../../components/Forms/Companies/Companies'
import { formatToTwoDecimals } from '../../../utils/computeds'

export default {
  components: { Companies },
  data () {
    return {
      tasks: [],
      search: {
        companyId: null,
        activity: '',
        status: 'PLANNED',
        userId: null,
        startDate: null,
        endDate: null
      },
      TASK_STATUS,
      skip: 0,
      take: 100,
      usersCompliance: [],
      weeklyData: {
        occupation: 0,
        currentWeek: 0,
        previousWeek: 0,
        twoWeeksAgo: 0,
        percentage: 0
      },
      monthlyCompliance: 0,
      annualPlan: {
        planned: 0,
        executed: 0,
        compliance: 0,
        added: 0
      },
      sortOrder: 'asc',
      currentWeekNumber: 0,
      previousWeekNumber: 0,
      twoWeeksAgoNumber: 0,
      startDateMenu: false,
      endDateMenu: false,
      companies: [],
      disabled: false
    }
  },
  computed: {
    isStatus () {
      return (status) => this.search.status !== status
    },
    ...mapGetters({
      user: 'getUser'
    }),
    OnlyAdmin,
    AdminCompliance
  },
  created () {
    this.setResponsible()
    this.searchTask()
    this.listUsersCompliance()
    // TODO:no llamar funciones para el rol admin
    this.loadWeeklyData()
    this.loadMonthlyCompliance()
    this.loadAnnualCompliance()
    this.listCompanies()
  },
  methods: {
    setResponsible () {
      if (
        this.user &&
        !this.OnlyAdmin(this.user.rol) &&
        !this.AdminCompliance(this.user.rol)
      ) {
        this.search.userId = this.user.id
      } else {
        this.search.userId = null
      }
    },
    async getTaskByStatus () {
      this.skip = 0
      this.take = 100
      this.disabled = false
      this.manualFilterCleaning()
      await this.searchTask()
    },
    async searchTask (addTask = false) {
      try {
        if (
          !this.OnlyAdmin(this.user.rol) &&
          !this.AdminCompliance(this.user.rol)
        ) {
          this.search.userId = this.user.id
        } else {
          this.search.userId = null
        }

        const tasks = await tasksByUser(
          this.search.companyId,
          this.search.status,
          this.search.activity,
          this.search.userId,
          this.search.startDate,
          this.search.endDate,
          this.skip,
          this.take,
          this.sortOrder
        )

        const taskData = Array.isArray(tasks.data) ? tasks.data : []

        if (!addTask) {
          this.tasks = taskData
        } else {
          this.tasks.push(...taskData)
        }

        if (taskData.length < this.take) {
          this.disabled = true
        }
      } catch (err) {
        console.log('search tasks:', err)
      }
    },
    async listUsersCompliance () {
      try {
        const userCompliance = await getCompliance()
        this.usersCompliance = userCompliance.map((user) => {
          user.fullname = `${user.name} ${user.lastName}`
          return user
        })
      } catch (err) {
        console.log('Error on list usersCompliance:', err)
      }
    },
    async manualSearchTask () {
      try {
        const tasks = await tasksByUser(
          this.search.companyId,
          this.search.status,
          this.search.activity,
          this.search.userId,
          this.search.startDate,
          this.search.endDate,
          this.skip,
          this.take,
          this.sortOrder
        )

        this.tasks = tasks.data

        if (this.skip + this.take > this.tasks.length) this.disabled = true
      } catch (err) {
        console.log('search tasks:', err)
      }
    },
    async loadWeeklyData () {
      try {
        const response = await getWeeklyUser(this.user.id)
        const {
          occupation,
          currentWeek,
          previousWeek,
          twoWeeksAgo,
          currentWeekNumber,
          previousWeekNumber,
          twoWeeksAgoNumber,
          percentage
        } = response

        this.weeklyData = {
          occupation,
          currentWeek,
          previousWeek,
          twoWeeksAgo,
          percentage
        }

        this.currentWeekNumber = currentWeekNumber
        this.previousWeekNumber = previousWeekNumber
        this.twoWeeksAgoNumber = twoWeeksAgoNumber
      } catch (error) {
        console.error('Error fetching weekly data:', error)
      }
    },
    async loadMonthlyCompliance () {
      try {
        const response = await getMonthlyCompliance(this.user.id)
        this.monthlyCompliance = response.monthlyCompliance
      } catch (error) {
        console.error('Error fetching monthly compliance:', error)
      }
    },
    async loadAnnualCompliance () {
      try {
        const response = await getAnnualCompliance(this.user.id)
        const { planned, executed, compliance, added } = response
        this.annualPlan = { planned, executed, compliance, added }
      } catch (error) {
        console.error('Error fetching annual compliance:', error)
      }
    },
    async toggleSortOrder () {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      await this.searchTask()
    },
    isWarning (plannedDate) {
      if (!plannedDate) return false

      const today = moment().startOf('day')
      const date = moment(plannedDate).startOf('day')
      const diffInDays = date.diff(today, 'days')
      return diffInDays <= 3 && diffInDays >= 0
    },
    isPastDue (plannedDate) {
      if (!plannedDate) return false
      const today = moment()
      const date = moment(plannedDate)
      return date.diff(today, 'days') < 0
    },
    openDocument (url) {
      window.open(url, '_blank')
    },
    async listCompanies () {
      try {
        this.companies = await getUserComplianceCompanies()
      } catch (err) {
        console.error('Error al listar las empresas:', err)
      }
    },
    updateCompany (id) {
      this.search.companyId = id
    },
    manualFilterCleaning () {
      this.search = {
        companyId: null,
        activity: '',
        startDate: null,
        endDate: null,
        userId: null,
        status: this.search.status
      }
      this.searchTask()
    },
    formatToTwoDecimals,
    async moreTask () {
      this.skip += this.take
      await this.searchTask(true)
    }
  }
}
</script>

<style lang="scss" src="./ComplianceByUser.scss" scoped></style>
