const isNumber = (evt) => {
  if (evt) {
    const charCode = evt.which ? evt.which : evt.keyCode
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode !== 46
    ) {
      evt.preventDefault()
    }
  }
}

// validate if val is undefined
const required = [(val) => !!val]

const calcANS = (services) => {
  return (
    services
      .reduce((r, s) => {
        r[s.order] = r[s.order] && r[s.order].ans > s.ans ? r[s.order] : s
        return r
      }, [])
      .reduce((total, service) => total + Number(service.ans), 0) || 0
  )
}

export {
  isNumber,
  required,
  calcANS
}
