import { ROL } from '../services/roles'

export function AdminAndCompanyAdmin () {
  return (rol) => [ROL.ADMIN, ROL.COMPANY_ADMIN].includes(rol)
};

export function NotAnalyst () {
  return (rol) => rol !== ROL.ANALYST
};

export function OnlyAdmin () {
  return (rol) => rol === ROL.ADMIN
};

export function OnlyRisk () {
  return (rol) => [ROL.ADMIN, ROL.RISK_SHIELD].includes(rol)
};

export function OnlyRiskAndAnalyst () {
  return (rol) => [ROL.ADMIN, ROL.RISK_SHIELD, ROL.ANALYST].includes(rol)
};

export function ComplianceAndAdmin () {
  return (rol) => [ROL.ADMIN, ROL.COMPLIANCE, ROL.COMPLIANCE_ADMIN].includes(rol)
};

export function AdminCompliance () {
  return (rol) => [ROL.COMPLIANCE_ADMIN].includes(rol)
};

export function NotComplianceAndNotAnalyst () {
  return (rol) => [ROL.ADMIN, ROL.RISK_SHIELD, ROL.COMPANY, ROL.COMPANY_ADMIN].includes(rol)
};

export function NotCompliance () {
  return (rol) => rol !== ROL.COMPLIANCE
};
