import axios from 'axios'
import store from '@/store'
import router from '@/router'

const instance = axios.create({
  baseURL: process.env.VUE_APP_URLAPI
})

let numLoadings = 0
const requestHandler = (request) => {
  numLoadings++
  store.dispatch('fetchLoading', true)
  return request
}
const responseHandler = (response) => {
  if ((--numLoadings) === 0) {
    store.dispatch('fetchLoading', false)
  }

  if (response && response.data) {
    return response.data
  }

  return response
}
const errorHandler = (error) => {
  if (error?.response?.statusCode === 401) {
    router.push('/login')
  }

  if (!(--numLoadings)) {
    store.dispatch('fetchLoading', false)
  }

  if (error.response && error.response.data) {
    return Promise.reject(error.response.data)
  }

  if (!error.response) {
    return Promise.reject(error.response)
  }

  return error
}

instance.interceptors.request.use(request => requestHandler(request))
instance.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(error)
)

export default instance
