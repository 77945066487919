<template src="./ListProcess.html"></template>
<script>
import { getProcesses } from '../../../services'
import Companies from '../../../components/Forms/Companies/Companies'
import Status from '../../../components/Forms/Status/Status'
import ProgressProcess from '../../../components/ProgressProcess/ProgressProcess'
import { mapGetters } from 'vuex'
import { OnlyRisk, OnlyRiskAndAnalyst, NotAnalyst } from '../../../utils/roles'
import { PROCESS_STATUS } from '../../../utils/constants'

export default {
  name: 'ListProcess',
  components: { Companies, ProgressProcess, Status },
  data: () => ({
    processes: [],
    menu: false,
    showMore: false,
    search: {
      company: null,
      text: '',
      status: 'PENDING',
      documentId: null,
      fullName: null
    },
    skip: 0,
    take: 50,
    disabled: false,
    PROCESS_STATUS
  }),
  created () {
    this.searchProcess()
  },
  methods: {
    async searchProcess (addProcess) {
      try {
        const processes = await getProcesses(
          this.search.companyId,
          this.search.status,
          this.search.documentId,
          this.search.fullName,
          this.skip,
          this.take
        )
        if (!addProcess) this.processes = processes
        else this.processes.push(...processes)

        if (this.skip + this.take > this.processes.length) this.disabled = true
      } catch (err) {
        console.log('search Process:', err)
      }
    },
    async getProcessByStatus () {
      this.skip = 0
      this.disabled = false
      await this.searchProcess()
    },
    async moreProcess () {
      this.skip += this.take
      await this.searchProcess(true)
    }
  },
  computed: {
    filterProcesses () {
      if (!this.search.text) return this.processes
      const search = this.search.text.toLowerCase()

      return this.processes.filter(
        (process) =>
          process.company.name.toLowerCase().includes(search) ||
          process.evaluated.fullName.toLowerCase().includes(search) ||
          process.evaluated.documentId.toLowerCase().includes(search)
      )
    },
    isStatus () {
      return (status) => this.search.status !== status
    },
    ...mapGetters({
      user: 'getUser'
    }),
    OnlyRisk,
    OnlyRiskAndAnalyst,
    NotAnalyst
  }
}
</script>
<style lang="scss" scoped src="./ListProcess.scss"></style>
