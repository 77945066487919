<template src="./ListServices.html"></template>
<script>
import { getServices, deleteServices } from '../../../services'

const filterItems = (query, data) => {
  return data.filter((el) => {
    const text = el.name + el.cost
    if (text.toLowerCase().indexOf(query.toLowerCase()) > -1) {
      return el
    }
  })
}

export default {
  name: 'ListServices',
  data: () => ({
    services: [],
    search: ''
  }),

  created () {
    this.listServices()
  },
  methods: {
    async listServices () {
      try {
        this.services = await getServices()
      } catch (err) {
        console.log('List services:', err)
      }
    },
    ifDeleteService (service) {
      this.$swal({
        title: '¿Está seguro de eliminar el servicio?',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then((value) => {
        if (value) {
          this.removeServices(service)
        }
      })
    },
    async removeServices (service) {
      try {
        await deleteServices(service.id)
        this.$swal({
          text: 'Se eliminó el servicio.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.services = []
        this.listServices()
      } catch (err) {
        console.error('Error on remove service', err)
        this.$swal({
          text: err.message || 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    }
  },
  computed: {
    filteredItems: function () {
      return filterItems(this.search, this.services)
    }
  }
}
</script>
