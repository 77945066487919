<template src="./File.html"></template>

<script>
export default {
  name: 'File',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    files: Array,
    upload: Function
  },
  data () {
    return {
      dragover: false,
      uploadedFiles: []
    }
  },
  methods: {
    addMoreFiles (files) {
      if (files.length) {
        for (const file of files) {
          this.files.push(file)
        }
      } else if (files) {
        this.files.shift()
        this.files.push(files)
      }
    },
    removeFile (fileName) {
      const index = this.files.findIndex((file) => file.name === fileName)

      if (index > -1) this.files.splice(index, 1)
    },
    onDrop (e) {
      this.dragover = false

      if (!this.multiple && this.files.length > 0) this.files.shift()

      if (e.dataTransfer.files.length) {
        for (const file of e.dataTransfer.files) {
          this.files.push(file)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped  src="./File.scss"></style>
