export const PROCESS_STATUS = Object.freeze({
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  FINISH: 'FINISH',
  CANCELLED: 'CANCELLED',
  INVOICED: 'INVOICED'
})

export const SERVICE_STATUS = Object.freeze({
  APPROVED: 'APPROVED',
  NOT_APPROVED: 'NOT_APPROVED',
  PENDING: 'PENDING',
  ASSIGNED: 'ASSIGNED',
  CANCELLED: 'CANCELLED'
})

export const processStatusVal = Object.freeze([
  {
    text: 'Pendiente',
    value: 'PENDING'
  },
  {
    text: 'Activo',
    value: 'ACTIVE'
  },
  {
    text: 'Finalizado',
    value: 'FINISH'
  },
  {
    text: 'Cancelado',
    value: 'CANCELLED'
  }
])

export const servicesStatusVal = Object.freeze([
  {
    text: 'Favorable',
    value: SERVICE_STATUS.APPROVED
  },
  {
    text: 'No Favorable',
    value: SERVICE_STATUS.NOT_APPROVED
  },
  {
    text: 'Cancelado',
    value: SERVICE_STATUS.CANCELLED
  }
])

export const TASK_STATUS = Object.freeze({
  PLANNED: 'PLANNED',
  EXECUTED: 'EXECUTED'
})
