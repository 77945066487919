<template src="./Messages.html"></template>
<script>
import { Mentionable } from 'vue-mention'
import { mapGetters } from 'vuex'
import { formatDate } from '../../utils/computeds'
import { saveMessage, getMessageByProcess } from '../../services/message'
import { OnlyRisk } from '../../utils/roles'

export default {
  name: 'Messages',
  props: {
    processId: String,
    usersAvailable: Array
  },
  components: { Mentionable },
  data: () => ({
    message: '',
    messages: [],
    items: [],
    email: null
  }),
  created () {
    this.listMessages()
  },
  methods: {
    reset () {
      this.message = ''
      this.listMessages()
    },
    async listMessages () {
      try {
        this.messages = await getMessageByProcess(this.processId)
      } catch (err) {
        console.log('List messages:', err)
      }
    },
    async addMessage () {
      try {
        if (!this.message) return
        await saveMessage(this.message, this.processId, this.email)

        this.reset()
        this.$swal({
          text: 'Se guardo el mensaje.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })

        this.email = null
      } catch (err) {
        console.error('save message by process:', err)
      }
    },
    asignEmail (item) {
      this.email = item.email
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    formatDate,
    OnlyRisk,
    getFullName () {
      return (user) => {
        return `${user.name} ${user.lastName}`
      }
    }
  }
}
</script>
