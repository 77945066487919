<template src="./CreateProcess.html"></template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import {
  searchEvaluated,
  getServiceByCompany,
  uploadFile,
  createFiles,
  createProcess,
  getAllUsers
} from '../../../services'
import File from '../../../components/Files/File/File'
import Companies from '../../../components/Forms/Companies/Companies'
import TypeDocument from '../../../components/Forms/TypeDocument/TypeDocument'
import { required, isNumber } from '../../../utils'
import { OnlyRisk } from '../../../utils/roles'

export default {
  name: 'CreateProcess',
  components: {
    Companies,
    TypeDocument,
    File
  },
  data: () => ({
    maxDate: moment().format('YYYY-MM-DD'),
    menu: false,
    activePicker: null,
    showEvaluated: false,
    companyId: null,
    evaluated: {
      typeDocument: 'CC'
    },
    process: { services: [] },
    servicesByCompany: [],
    groupServicesByCompany: [],
    files: [],
    required,
    showMore: false,
    users: [],
    menuCreated: false,
    menuPromise: false
  }),
  methods: {
    isNumber,
    async search () {
      try {
        const evaluated = await searchEvaluated(
          this.companyId,
          this.evaluated.typeDocument,
          this.evaluated.documentId
        )
        if (!evaluated?.typeDocument || !evaluated?.documentId) {
          evaluated.typeDocument = this.evaluated.typeDocument
          evaluated.documentId = this.evaluated.documentId
        }
        if (evaluated?.expeditionDate) {
          evaluated.expeditionDate = moment(evaluated?.expeditionDate).format(
            'YYYY-MM-DD'
          )
        }

        this.evaluated = evaluated
        this.showEvaluated = true
        setTimeout(() => this.$refs.fullName.focus(), 300)
      } catch (err) {
        console.error('Error on search evaluated:', err)
      }
    },
    async getCompanyServices (companyId) {
      try {
        if (companyId) {
          const [
            servicesByCompany,
            ...groupServicesByCompany
          ] = await getServiceByCompany(companyId)
          this.servicesByCompany = servicesByCompany || []
          this.groupServicesByCompany = groupServicesByCompany || []
          this.process.services = []

          if (OnlyRisk()(this.user.rol)) {
            const users = await getAllUsers({
              company: companyId
            })
            this.users = users.map((user) => {
              user.fullname = `${user.name} ${user.lastName}`
              return user
            })
          }
        }
      } catch (err) {
        console.error('Error on list company services:', err)
      }
    },
    selectService (service, toggle) {
      this.process.services = service.length ? service : [service]
      toggle()
    },
    async create () {
      if (
        this.$refs.evaluatedform.validate() &&
        this.$refs.searchForm.validate() &&
        this.process.services.length
      ) {
        if (this.evaluated.phoneNumber) this.evaluated.phoneNumber = this.evaluated.phoneNumber.replace(/\D/g, '')
        const data = {
          process: this.process,
          evaluated: this.evaluated,
          companyId: this.companyId
        }
        try {
          const process = await createProcess(data)

          // If it has files
          if (this.files.length) {
            this.$store.dispatch('fetchLoading', true)
            const files = await Promise.all(
              this.files.map(async (file) => ({
                url: await uploadFile(
                  'Processes',
                  `${new Date().getTime()}-${file.name}`,
                  file
                ),
                name: file.name
              }))
            )
            await createFiles(files, process.id)
            this.files = []
          }

          this.$swal({
            text: 'Se creo el proceso exitosamente.',
            buttons: false,
            icon: 'success',
            timer: 3000
          })
          this.$refs.searchForm.reset() // Limpia todo el form
          this.$refs.evaluatedform.reset() // Limpia todo el form
        } catch (error) {
          console.error(`Error on create process: ${JSON.stringify(data)}`, JSON.stringify(error))
          this.$swal({
            text: 'Ocurrio un error, intenta mas tarde.',
            buttons: false,
            icon: 'error',
            timer: 3000
          })
        }
      }

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    canSearch () {
      return (
        !this.companyId || !this.evaluated?.typeDocument || !this.evaluated?.documentId
      )
    },
    OnlyRisk
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  }
}
</script>
<style lang="scss" scoped src="./CreateProcess.scss"></style>
