import axios from '../plugins/axios'

const getInvoiceCompanies = (companyId, skip, take) =>
  axios.get('/invoice/companies', {
    params: { companyId, skip, take }
  })
const getInvoices = (number, date, skip, take) =>
  axios.get('/invoices', {
    params: { number, date, skip, take }
  })
const getInvoiceCompany = companyId => axios.get(`/invoice/${companyId}`)
const getInvoiceProcess = id => axios.get(`/invoice/${id}/process`)
const createInvoice = data => axios.post('/invoice', data)
const updateInvoice = (id, data) => axios.put(`/invoice/${id}`, data)

export { getInvoiceCompanies, getInvoiceCompany, createInvoice, getInvoices, updateInvoice, getInvoiceProcess }
