<template src="./ListInvoices.html"></template>

<script>
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import { getInvoices, updateInvoice, getInvoiceProcess } from '../../../services'
import { formatDateUTC } from '../../../utils/computeds'
import { isNumber } from '../../../utils'

export default {
  name: 'Invoice',
  data: () => ({
    menu: false,
    menuUpdate: false,
    disabled: false,
    skip: 0,
    take: 50,
    search: {
      number: null,
      date: moment().format('YYYY-MM')
    },
    invoice: { number: null },
    invoices: [],
    processes: [],
    updateModal: false,
    processesModal: false
  }),
  validations () {
    return {
      invoice: {
        date: { required },
        number: { required },
        total: { required }
      }
    }
  },
  created () {
    this.searchInvoices()
  },
  methods: {
    isNumber,
    async searchInvoices (add) {
      try {
        const invoices = await getInvoices(this.search.number, this.search.date, this.skip, this.take)

        if (!add) this.invoices = invoices
        else this.invoices.push(...invoices)

        if (this.skip + this.take > this.invoices.length) this.disabled = true
        else this.disabled = false
      } catch (error) {
        console.log('Error on get invoices =>', error)
      }
    },
    async more () {
      this.skip += this.take
      await this.searchInvoices(true)
    },
    selectUpdate (invoice) {
      this.invoice = JSON.parse(JSON.stringify(invoice))
      this.invoice.date = moment(this.invoice.date).format('YYYY-MM-DD')
      this.updateModal = true
    },
    async update (invoice) {
      try {
        await updateInvoice(invoice.id, invoice)
        await this.searchInvoices()
        this.$swal({
          text: 'Se actualizo la factura.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.updateModal = false
      } catch (error) {
        console.log('Error on update invoice =>', error)
        this.$swal({
          text: error.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    async listProcesses (invoice) {
      this.invoice = invoice
      this.processes = await getInvoiceProcess(invoice.id)
      this.processesModal = true
    }
  },
  computed: {
    formatDateUTC
  }
}
</script>
