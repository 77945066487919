<template src="./Layout.html"></template>
<style lang="scss" src="./Layout.scss" scoped></style>
<script>
import '@/assets/styles/base.scss'
import { doc, onSnapshot } from 'firebase/firestore'
import { mapGetters } from 'vuex'
import { logOff, getRoleName } from '../services'
import Loading from '../components/Loading/Loading'
import Menu from '../components/Menu/Menu'
import { db } from '../plugins/firebase'

export default {
  name: 'Layout',
  components: {
    Loading,
    Menu
  },
  data: () => ({
    drawer: null,
    profile: false,
    maintenance: false
  }),
  created () {
    this.getParameters()
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      whatsapp: 'getWhatsapp'
    }),
    getRole: function () {
      return (rol) => getRoleName(rol)
    }
  },
  methods: {
    getParameters () {
      onSnapshot(doc(db, 'APP', 'CONFIG'), (doc) => {
        const { MAINTENANCE } = doc.data()
        this.maintenance = MAINTENANCE
      })
    },
    async signOut () {
      await logOff()
      localStorage.removeItem('auth')
      this.$store.dispatch('pushUser', null)
      this.$user = null
      this.$router.push('/login')
    }
  }
}
</script>
