import { firebaseAuth } from '../plugins/firebase'
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail, signOut } from 'firebase/auth'
const googleProvider = new GoogleAuthProvider()

const login = (email, password) => signInWithEmailAndPassword(firebaseAuth, email, password)
const loginGoogle = () => signInWithPopup(firebaseAuth, googleProvider)
const forget = (email) => sendPasswordResetEmail(firebaseAuth, email)
const logOff = () => signOut(firebaseAuth)

export {
  login,
  loginGoogle,
  forget,
  logOff
}
