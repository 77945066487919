<template src="./Invoice.html"></template>

<script>
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import Companies from '../../../components/Forms/Companies/Companies'
import { getInvoiceCompanies, getInvoiceCompany, createInvoice } from '../../../services'
import {
  formatDateUTC,
  getStatusServiceChip,
  getServiceStatus
} from '../../../utils/computeds'
import { isNumber } from '../../../utils'
import { SERVICE_STATUS } from '../../../utils/constants'

export default {
  name: 'Invoice',
  components: { Companies },
  data: () => ({
    SERVICE_STATUS,
    search: {
      companyId: null
    },
    skip: 0,
    take: 20,
    companies: [],
    processes: [],
    companyActive: null,
    invoice: {
      date: null,
      number: null,
      total: null,
      processes: [],
      observation: null
    },
    services: [],
    calcInvoice: {
      services: 0,
      extras: 0,
      total: 0
    },
    panels: [],
    checkedAll: false,
    menu: false,
    dialog: false
  }),
  validations () {
    return {
      invoice: {
        date: { required },
        number: { required },
        total: { required }
      }
    }
  },
  created () {
    this.searchInvoiceCompanies()
  },
  methods: {
    isNumber,
    reset () {
      this.invoice = {
        date: moment().utc().format('YYYY-MM-DD'),
        number: null,
        total: null,
        processes: [],
        observation: null
      }
      this.panels = []
      this.calcInvoice = {
        services: 0,
        extras: 0,
        total: 0
      }
      this.checkedAll = false
      this.dialog = false
    },
    async searchInvoiceCompanies () {
      try {
        this.companies = await getInvoiceCompanies(
          this.search.companyId,
          this.skip,
          this.take
        )
        if (this.companies.length) this.selectCompany(this.companies[0])
      } catch (err) {
        console.log('search Invoice Companies:', err)
      }
    },
    async getCompanyInvoice (companyId) {
      try {
        this.processes = await getInvoiceCompany(companyId)
      } catch (err) {
        console.log('get Company Invoice:', err)
      }
    },
    selectCompany (company) {
      this.reset()
      this.companyActive = company
      this.getCompanyInvoice(company.id)
    },
    seeAllProcess (quantity) {
      if (quantity === 0) this.panels = []
      else this.panels = [...Array(quantity + 1).keys()]
    },
    calculate () {
      this.services = []
      const services = []
      this.calcInvoice = this.invoice.processes.reduce(
        (res, processId) => {
          const process = this.processes.find((process) => process.id === processId)
          const [service] = process.serviceByProcess

          if (!service.groupId) {
            services.push({
              name: service.name,
              quantity: 1,
              total: service.value
            })
          } else {
            const someCancelled = process.serviceByProcess.some(
              (service) => service.status === SERVICE_STATUS.CANCELLED
            )
            if (!someCancelled) {
              services.push({
                name: service.groupName,
                quantity: 1,
                total: service.groupTotal
              })
            } else {
              services.push({
                name: 'Otros Servicios',
                quantity: 1,
                total: process.total
              })
            }
          }
          res.extras += process.extraCost
          res.services += process.total

          return res
        },
        { services: 0, extras: 0, total: 0 }
      )
      this.calcInvoice.total = this.calcInvoice.services + this.calcInvoice.extras

      this.services = services.reduce((res, service) => {
        const index = res.findIndex((item) => item.name === service.name)
        if (index !== -1) {
          res[index].total += service.total
          res[index].quantity += service.quantity
        } else {
          res.push(service)
        }
        return res
      }, [])
    },
    check (e, process) {
      e.cancelBubble = true
      this.checkedAll = false

      if (this.invoice.processes.includes(process.id)) {
        this.invoice.processes = this.invoice.processes.filter(
          (processId) => processId !== process.id
        )
        process.checked = false
      } else {
        this.invoice.processes.push(process.id)
        process.checked = true
      }
      this.calculate()
    },
    checkAll (e) {
      e.cancelBubble = true

      if (!this.checkedAll) this.invoice.processes = []
      else this.invoice.processes = this.processes.map((process) => process.id)

      for (const process of this.processes) {
        process.checked = this.checkedAll
      }
      this.calculate()
    },
    async saveInvoice () {
      try {
        await createInvoice(this.invoice)
        await this.searchInvoiceCompanies()

        this.$swal({
          text: 'Se creo la factura.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.reset()
      } catch (error) {
        console.log('Error on create invoice =>', error)
        this.$swal({
          text: error.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    }
  },
  computed: {
    formatDateUTC,
    getStatusServiceChip,
    getServiceStatus
  }
}
</script>
<style lang="scss" scoped src="./Invoice.scss"></style>
