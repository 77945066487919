import axios from '../plugins/axios'

const getCompliance = () => axios.get('/compliance/user')
const assignComplianceHours = hoursCompliance => axios.post('/compliance/hours', hoursCompliance)
const addUsersCompliance = usersCompliance => axios.post('/compliance/users', usersCompliance)
const getAssignedUsers = (id) => axios.get(`/compliance/company/users/${id}`)
const removeUserAssignment = id => axios.delete('/compliance/user', { data: { id } })
const createPlanTemplate = planTemplate => axios.post('/compliance/plan/template', planTemplate)
const getTemplatesPlans = () => axios.get('/compliance/plan/templates')
const getAllTemplatesPlans = () => axios.get('/compliance/plan/all/templates')
const assignPlanToCompany = planCompany => axios.post('/compliance/plan/company', planCompany)
const saveHoursReport = ReportHours => axios.post('/compliance/report/hour', ReportHours)
const tasksByUser = (companyId, status, activity, userId, startDate, endDate, skip, take, sortOrder) => axios.get('/compliance/hours/user', {
  params: { companyId, status, activity, userId, startDate, endDate, skip, take, sortOrder }
})
const getWeeklyUser = (userId) => axios.get(`/compliance/weekly/${userId}`)
const getMonthlyCompliance = (userId) => axios.get(`/compliance/monthly/${userId}`)
const getAnnualCompliance = (userId) => axios.get(`/compliance/annual/${userId}`)
const getUsersCompliance = () => axios.get('/compliance/users/admin')
const getComplianceByCompany = (params) => axios.get('/compliance/company/admin', { params })
const updateHoursReport = ReportHours => axios.put('/compliance/report/hour', ReportHours)
const updatePlan = plan => axios.put('/compliance/plan/template', plan)
const getUserComplianceCompanies = () => axios.get('/companies/user/compliance')

export {
  assignComplianceHours,
  getCompliance,
  addUsersCompliance,
  getAssignedUsers,
  removeUserAssignment,
  createPlanTemplate,
  getTemplatesPlans,
  getAllTemplatesPlans,
  assignPlanToCompany,
  saveHoursReport,
  tasksByUser,
  getWeeklyUser,
  getMonthlyCompliance,
  getAnnualCompliance,
  getUsersCompliance,
  getComplianceByCompany,
  updateHoursReport,
  updatePlan,
  getUserComplianceCompanies
}
