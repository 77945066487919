<template src="./Analysts.html"></template>

<script>
import { getAnalysts } from '../../../services'

export default {
  name: 'Analysts',
  props: {
    value: [String, Object],
    change: Function
  },
  model: {
    prop: 'value',
    event: 'update'
  },
  data: () => ({
    analysts: [],
    rules: []
  }),
  created () {
    this.listAnalysts()
  },
  methods: {
    async listAnalysts () {
      try {
        const analysts = await getAnalysts()
        this.analysts = analysts.map((analyst) => {
          analyst.fullname = `${analyst.name} ${analyst.lastName}`
          return analyst
        })
      } catch (err) {
        console.log('Error on list analysts:', err)
      }
    },
    changeValue (e) {
      if (this.change) this.change(e)
    }
  }
}
</script>
