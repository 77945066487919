<template src="./Company.html"></template>

<script>
import { required } from 'vuelidate/lib/validators'
import { createCompany, updateCompany } from '../../../services'

export default {
  name: 'Company',
  data: () => ({
    company: { active: true },
    update: false
  }),
  validations () {
    return {
      company: {
        name: { required },
        nit: { required },
        email: { required },
        phoneNumber: { required },
        city: { required }
      }
    }
  },
  created () {
    this.isUpdateCompany()
  },
  methods: {
    isUpdateCompany () {
      if (this.$route.params.updateCompany) {
        this.company = this.$route.params.updateCompany
        this.update = true
      }
    },
    reset () {
      this.company = {}
      this.update = false
    },
    async saveCompany () {
      try {
        if (this.$v.$invalid) {
          return false
        } else {
          await createCompany(this.company)
          this.reset()
          this.$swal({
            text: 'Se agregó la empresa.',
            buttons: false,
            icon: 'success',
            timer: 3000
          })
        }
      } catch (err) {
        console.error('Error on save company', err)
        this.$swal({
          text: err.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    async changeCompany (company) {
      try {
        await updateCompany(company)
        this.$swal({
          text: 'Se Actualizo la empresa.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.reset()
      } catch (err) {
        console.error('Error on update company', err)
        this.$swal({
          text: err.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    }
  }
}
</script>
