import Vue from 'vue'
import Vuex from 'vuex'
import Loading from './modules/Loading'
import User from './modules/User'
import App from './modules/App'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    App,
    Loading,
    User
  }
})
