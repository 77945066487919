import axios from '@/plugins/axios'
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
const storage = getStorage()

// The name must go with the file extension, ejem: file.PDF
const uploadFile = async (folder, name, file) => {
  const storageRef = ref(storage, `${folder}/${name}`)
  return uploadBytes(storageRef, file)
    .then(async snapshot => {
      return await getDownloadURL(snapshot.ref)
    })
}

const deleteFileFirebase = async (url) => {
  const storage = getStorage()
  const desertRef = ref(storage, url)
  return deleteObject(desertRef)
}

const createFiles = (files, processId) => {
  if (!files.length) files = [files]
  return axios.post('/files', { files, processId })
}
const getFilesByProcess = process => axios.get(`/file/${process}`)
const deleteFile = id => axios.delete(`/file/${id}`)

export {
  createFiles,
  getFilesByProcess,
  deleteFile,
  deleteFileFirebase,
  uploadFile
}
