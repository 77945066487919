import Vue from 'vue'
import Layout from './layout/Layout'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSwal from 'vue-swal'
import Vuelidate from 'vuelidate'

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VueSwal)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(Layout)
}).$mount('#app')
