import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routers'
import Store from '../store'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

if (process.env.VUE_APP_ENV === 'PROD') {
  Sentry.init({
    Vue: Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    integrations: [
      new CaptureConsole({
        levels: ['error']
      }),
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['*', /^\//]
      })
    ],
    maxBreadcrumbs: 50,
    tracesSampleRate: 1.0
  })
}

router.beforeEach((to, from, next) => {
  Store.dispatch('fetchWhatsapp', to.meta.whatsapp ?? true)

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = localStorage.getItem('auth')
    if (!user) {
      next({ path: '/login' })
    }
    next()
  } else {
    next()
  }
})

export default router
