<template src="./FileUpload.html"></template>

<script>
import { formatDate } from '../../../utils/computeds'
import {
  uploadFile,
  createFiles,
  getFilesByProcess,
  deleteFile,
  deleteFileFirebase
} from '../../../services'
import { OnlyRisk } from '../../../utils/roles'
import { mapGetters } from 'vuex'

export default {
  name: 'FileUpload',
  data: () => ({
    file: null,
    percentage: 0,
    files: []
  }),
  props: {
    processId: String
  },
  created () {
    this.listFile()
  },
  methods: {
    reset () {
      this.listFile()
      this.file = null
    },
    async listFile () {
      try {
        this.files = await getFilesByProcess(this.processId)
      } catch (err) {
        console.log('Error on list files:', err)
      }
    },
    async filesUpload () {
      try {
        const url = await uploadFile(
          'Processes',
          `${new Date().getTime()}-${this.file.name}`,
          this.file
        )

        await createFiles(
          {
            name: this.file.name,
            url: url
          },
          this.processId
        )
        this.$swal({
          text: 'Se subio el archivo con exito.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.reset()
      } catch (error) {
        console.error('Error on upload file', error)
        this.$swal({
          text: 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    async deleteFile (file) {
      this.$swal({
        text: '¿Esta seguro de eliminar el archivo?',
        icon: 'warning',
        buttons: ['NO', 'SI'],
        dangerMode: true
      }).then(async (willDelete) => {
        if (willDelete) {
          try {
            await Promise.all([
              deleteFileFirebase(file.url),
              deleteFile(file.id)
            ])
            this.$swal({
              text: 'El archivo se elimino con exito',
              buttons: false,
              icon: 'success',
              timer: 3000
            })
            this.reset()
          } catch (err) {
            console.error('Error delete file', err)
          }
        }
      })
    }
  },
  computed: {
    formatDate,
    OnlyRisk,
    ...mapGetters({
      user: 'getUser'
    })
  }
}
</script>
