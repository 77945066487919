<template src="./TypeDocument.html"></template>

<script>
export default {
  name: 'TypeDocument',
  props: {
    value: String,
    isDisabled: Boolean,
    isRequired: Boolean
  },
  model: {
    prop: 'value',
    event: 'update'
  },
  data: () => ({
    typeDocuments: [
      {
        id: 'CC',
        name: 'Cédula de Ciudadanía'
      },
      {
        id: 'CE',
        name: 'Cédula de Extranjería'
      },
      {
        id: 'PA',
        name: 'Pasaporte'
      },
      {
        id: 'NIT',
        name: 'Nit'
      }
    ],
    rules: []
  }),
  mounted () {
    if (this.isRequired) this.rules = [(val) => !!val]
  }
}
</script>
