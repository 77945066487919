import axios from '@/plugins/axios'

const createService = service => axios.post('/service', service)
const getServices = () => axios.get('/service')
const updateService = service => axios.put('/service', service)
const deleteServices = id => axios.delete('/service', { data: { id } })
const createServiceByCompany = serviceCompany => axios.post('service/company', serviceCompany)
const getServiceByCompany = (id) => axios.get(`/service/company/${id}`)
const deleteServiceByCompany = (id) => axios.delete(`/service/${id}/company`)
const updateServiceByCompany = (id, service) => axios.patch(`/service/${id}/company`, service)
const updateGroupByCompany = (id, group) => axios.patch(`/service/group/${id}`, group)

export {
  createService,
  getServices,
  updateService,
  deleteServices,
  createServiceByCompany,
  getServiceByCompany,
  deleteServiceByCompany,
  updateServiceByCompany,
  updateGroupByCompany
}
