export default {
  state: {
    user: null
  },
  getters: {
    getUser: state => state.user
  },
  mutations: {
    setUser (state, value) {
      state.user = value
    }
  },
  actions: {
    pushUser ({ commit }, user) {
      commit('setUser', user)
    }
  }
}
