import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#636569',
        secondary: '#fc5b00',
        PENDING: '#fc5b00',
        CANCELLED: '#848484',
        ACTIVE: '#1B5E20',
        FINISH: '#636569',
        INVOICED: '#636569',
        PLANNED: '#fc5b00',
        EXECUTED: '#636569'
      }
    }
  }
})
