<template src="./ListCompanies.html"></template>
<script>
import { requiredIf, minLength } from 'vuelidate/lib/validators'
import { getCompanies, deleteCompany } from '../../../services'

export default {
  name: 'ListCompanies',
  data: () => ({
    companies: [],
    search: {},
    lastSearch: [],
    take: 20,
    skip: 0,
    disabled: false
  }),
  validations () {
    return {
      search: {
        email: { required: requiredIf(this.searchIfEmpty()) },
        nit: {
          required: requiredIf(this.searchIfEmpty()),
          minLength: minLength(2)
        },
        name: {
          required: requiredIf(this.searchIfEmpty()),
          minLength: minLength(2)
        }
      }
    }
  },
  created () {
    this.searchCompanies()
  },
  methods: {
    searchIfEmpty () {
      return Object.keys(this.search).length
    },
    async searchCompanies (addCompanies) {
      try {
        if (Object.keys(this.search).length) {
          this.skip = 0
          this.lastSearch = []
        }

        const companies = await getCompanies({
          take: this.take,
          skip: this.skip,
          ...this.search
        })
        if (!addCompanies) this.companies = companies
        else this.companies.push(...companies)

        this.getLastSearch(this.search)

        if (this.skip + this.take > this.companies.length) this.disabled = true
        else this.disabled = false

        this.search = {}
      } catch (err) {
        console.log('Error on search Companies:', err)
      }
    },
    ifDeleteCompany (company) {
      this.$swal({
        title: '¿Está seguro de eliminar la empresa?',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then((value) => {
        if (value) {
          this.removeCompany(company)
        }
      })
    },
    async removeCompany (company) {
      try {
        await deleteCompany(company.id)
        this.$swal({
          text: 'Se eliminó la empresa.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.companies = this.companies.filter(
          (client) => client.id !== company.id
        )
      } catch (err) {
        console.error('Error on remove company', err)
        this.$swal({
          text: err.message || 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    async moreCompanies () {
      this.skip += this.take
      await this.searchCompanies(true)
    },
    getLastSearch (search) {
      this.lastSearch = Object.keys(search).reduce((res, item) => {
        if (!search[item]) return res
        switch (item) {
          case 'name':
            res.push({
              name: 'Nombre',
              value: search[item]
            })
            break
          case 'email':
            res.push({
              name: 'Correo',
              value: search[item]
            })
            break
          case 'nit':
            res.push({
              name: 'Nit',
              value: search[item]
            })
            break
        }
        return res
      }, [])
    },
    cleanSearch () {
      this.lastSearch = []
      this.searchCompanies()
    }
  }
}
</script>
