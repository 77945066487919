<template src="./Menu.html"></template>

<script>
import { mapGetters } from 'vuex'
import { getRoleName } from '../../services'
import {
  AdminAndCompanyAdmin,
  NotAnalyst,
  OnlyRisk,
  OnlyAdmin,
  ComplianceAndAdmin,
  NotComplianceAndNotAnalyst,
  NotCompliance,
  AdminCompliance
} from '../../utils/roles'

export default {
  name: 'Menu',
  computed: {
    AdminAndCompanyAdmin,
    NotAnalyst,
    OnlyRisk,
    OnlyAdmin,
    ComplianceAndAdmin,
    NotComplianceAndNotAnalyst,
    NotCompliance,
    AdminCompliance,
    ...mapGetters({
      user: 'getUser'
    }),
    getRole: function () {
      return (rol) => getRoleName(rol)
    }
  }
}
</script>
