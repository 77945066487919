<template src="./ListCompliance.html"></template>

<script>
import { getAllTemplatesPlans } from '../../../services'

export default {
  name: 'ListCompliance',
  data () {
    return {
      plans: []
    }
  },
  created () {
    this.getPlansCompliance()
  },

  methods: {
    async getPlansCompliance () {
      try {
        this.plans = await getAllTemplatesPlans()
      } catch (err) {
        console.log('Error on get templates plans:', err)
      }
    }
  }
}
</script>

<style lang="scss" src="./ListCompliance.scss" scoped></style>
