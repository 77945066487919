<template src="./UsersCompliance.html"></template>

<script>
import { getCompliance } from '../../../services'

export default {
  name: 'UsersCompliance',
  props: {
    value: [String, Object],
    change: Function
  },
  model: {
    prop: 'value',
    event: 'update'
  },
  data () {
    return {
      usersCompliance: []
    }
  },
  created () {
    this.listUsersCompliance()
  },
  methods: {
    async listUsersCompliance () {
      try {
        const userCompliance = await getCompliance()
        this.usersCompliance = userCompliance.map((user) => {
          user.fullname = `${user.name} ${user.lastName}`
          return user
        })
      } catch (err) {
        console.log('Error on list usersCompliance:', err)
      }
    },
    emitUserDetails (id) {
      const selectedUser = this.usersCompliance.find((user) => user.id === id)
      if (selectedUser) {
        const fullname = `${selectedUser.name} ${selectedUser.lastName}`
        this.$emit('update', { id: selectedUser.id, fullname })
      }
    },
    changeValue (e) {
      if (this.change) this.change(e)
    }
  }
}
</script>
