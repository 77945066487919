<template src="./CreateCompliance.html"></template>

<script>
import { createPlanTemplate, updatePlan } from '../../../services/compliance'
import TaskList from '../../../components/Forms/TaskList/TaskList'

export default {
  name: 'CreateCompliance',
  components: {
    TaskList
  },
  data () {
    return {
      plan: {
        name: '',
        observation: ''
      },
      newTask: {
        activity: '',
        assignedHours: 0,
        plannedDate: new Date().toISOString().substr(0, 10),
        guide: ''
      },
      tasks: [],
      showTaskForm: false,
      menu: false,
      update: false
    }
  },
  created () {
    if (this.$route.params.updatePlan) {
      this.isUpdatePlan()
      this.update = true
    }
  },
  methods: {
    isUpdatePlan () {
      if (this.$route.params.updatePlan) {
        this.plan = { ...this.$route.params.updatePlan }
        this.tasks = this.plan.tasks || []
      }
    },
    addTask () {
      if (!this.newTask.activity) {
        this.$swal({
          text: 'La actividad es obligatoria',
          buttons: false,
          icon: 'warning',
          timer: 3000
        })
        return
      }
      if (!this.newTask.guide) {
        this.$swal({
          text: 'El campo documento guía es obligatorio',
          buttons: false,
          icon: 'warning',
          timer: 3000
        })
        return
      }
      this.tasks.push({
        ...this.newTask,
        assignedHours: Number(this.newTask.assignedHours)
      })
      this.newTask.activity = ''
      this.newTask.plannedDate = new Date().toISOString().substr(0, 10)
      this.newTask.guide = ''
      this.newTask.assignedHours = 0
      this.showTaskForm = false
    },
    showFormTask () {
      this.showTaskForm = true
    },
    async savePlan () {
      try {
        if (this.plan.name && this.tasks.length > 0) {
          const planData = {
            ...this.plan,
            tasks: this.tasks.map((task) => ({
              ...task,
              plannedDate: new Date(task.plannedDate)
            }))
          }
          await createPlanTemplate(planData)
          this.$swal({
            text: 'Plantilla creada correctamente.',
            buttons: false,
            icon: 'success',
            timer: 3000
          })
          this.$router.push('/planes-cumplimiento')
        } else if (!this.plan.name) {
          this.$swal({
            text: 'El nombre del plan y la descripción son obligatorios',
            buttons: false,
            icon: 'warning',
            timer: 3000
          })
        } else {
          this.$swal({
            text: 'Debe agregar una tarea',
            buttons: false,
            icon: 'warning',
            timer: 3000
          })
        }
      } catch (error) {
        console.log('save plan:', error)
      }
    },
    async changePlan () {
      if (this.plan.name && this.tasks.length > 0) {
        const organizedTasks = this.tasks.map((task) => {
          return {
            activity: task.activity,
            assignedHours: task.assignedHours,
            plannedDate: task.plannedDate,
            guide: task.guide,
            id: task.id || undefined
          }
        })
        const planData = {
          id: this.plan.id,
          name: this.plan.name,
          observation: this.plan.observation,
          tasks: organizedTasks
        }
        await updatePlan(planData)
        this.$swal({
          text: 'Plan actualizado correctamente.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.$router.push('/planes-cumplimiento')
      } else if (!this.plan.name) {
        this.$swal({
          text: 'El nombre del plan y la descripción son obligatorios',
          buttons: false,
          icon: 'warning',
          timer: 3000
        })
      } else {
        this.$swal({
          text: 'Debe agregar una tarea',
          buttons: false,
          icon: 'warning',
          timer: 3000
        })
      }
    },
    removeTask (task) {
      this.tasks = this.tasks.filter((t) => t !== task)
    },
    cancelTask () {
      this.newTask.activity = ''
      this.newTask.assignedHours = 0
      this.newTask.plannedDate = new Date().toISOString().substr(0, 10)
      this.newTask.guide = ''
      this.showTaskForm = false
    }
  }
}
</script>

<style lang="scss" src="./CreateCompliance.scss" scoped></style>
