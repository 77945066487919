<template src="./Process.html"></template>
<script>
import moment from 'moment'
import {
  getProcess,
  updateProcess,
  assignServiceByProcess,
  qualifyServiceByProcess,
  uploadFile
} from '../../../services'
import FileUpload from '../../../components/Files/FileUpload/FileUpload'
import File from '../../../components/Files/File/File'
import ProgressProcess from '../../../components/ProgressProcess/ProgressProcess'
import Messages from '../../../components/Messages/Messages'
import Analysts from '../../../components/Forms/Analysts/Analysts'
import Status from '../../../components/Forms/Status/Status'
import {
  formatDateUTC,
  formatDate,
  getProcessStatus,
  getError,
  getStatusService
} from '../../../utils/computeds'
import { SERVICE_STATUS } from '../../../utils/constants'
import { mapGetters } from 'vuex'
import { OnlyAdmin, OnlyRiskAndAnalyst, OnlyRisk } from '../../../utils/roles'
import { isNumber } from '../../../utils'

export default {
  name: 'Process',
  components: {
    File,
    FileUpload,
    Messages,
    ProgressProcess,
    Analysts,
    Status
  },
  data: () => ({
    SERVICE_STATUS,
    error: null,
    process: {
      evaluated: {}
    },
    processId: null,
    qualify: false,
    service: null,
    report: [],
    showReport: false,
    processModal: false,
    updateProcess: {},
    menuPromiseDate: false,
    menuCreatedAt: false,
    showEvaluated: false,
    userAvailable: [],
    removeModal: false
  }),
  created () {
    this.processId = this.$route.params.id
    this.listProcess()
  },
  methods: {
    isNumber,
    getUsersAvailable (process) {
      this.userAvailable = process.serviceByProcess.reduce((users, service) => {
        if (
          service.userAssigned &&
          !users.some((user) => user.email === service.userAssigned.email)
        ) {
          users.push({
            value: `${service.userAssigned.name} ${service.userAssigned.lastName}`,
            email: service.userAssigned.email
          })
        }
        return users
      }, [])
      this.userAvailable.push({
        value: `${process.userCreated.name} ${process.userCreated.lastName}`,
        email: process.userCreated.email
      })
    },
    async listProcess () {
      try {
        this.process = await getProcess(this.$route.params.id)
        this.showReport = !this.process.serviceByProcess.some(
          (service) =>
            service.status === SERVICE_STATUS.PENDING ||
            service.status === SERVICE_STATUS.ASSIGNED
        )
        this.updateProcess.status = this.process.status
        this.updateProcess.promiseDate = moment(
          this.process.promiseDate
        ).format('YYYY-MM-DD')
        this.updateProcess.createdAt = moment(this.process.createdAt).format(
          'YYYY-MM-DD'
        )

        this.getUsersAvailable(this.process)
      } catch (err) {
        console.log('Error on get process:', err)
        if (err.message) this.error = err
      }
    },
    async saveServiceByAnalyst (service) {
      try {
        const newService = await assignServiceByProcess(service.id, service.user, this.processId)
        newService.options = { editAssigned: false }

        this.process.serviceByProcess = this.process.serviceByProcess.map(service => {
          if (service.id === newService.id) {
            service = newService
          }
          return service
        })

        this.$swal({
          text: 'Se asigno el analista al servicio.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        // await this.listProcess(); //TEMPORAL
      } catch (err) {
        console.error('save message by process:', err)
        this.$swal({
          text: err.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    qualifyModal (service) {
      this.service = service
      this.qualify = true
    },
    async qualifyService (status) {
      try {
        await qualifyServiceByProcess(this.service.id, status, this.processId)
        this.$swal({
          text: 'Se califico el servicio.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        await this.listProcess()
        this.service = null
        this.qualify = false
      } catch (err) {
        console.error('Error on qualify service:', err)
        this.$swal({
          text: err.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    editService (service) {
      if (
        service.status !== SERVICE_STATUS.APPROVED &&
        service.status !== SERVICE_STATUS.NOT_APPROVED &&
        service.status !== SERVICE_STATUS.CANCELLED
      ) {
        service.options.editAssigned = true
      } else this.qualifyModal(service)
    },
    async loadReport () {
      try {
        if (this.report.length) {
          const [file] = this.report
          this.$store.dispatch('fetchLoading', true)
          const report = await uploadFile(
            'Reports',
            `${this.process.id}-${this.process.evaluated.documentId}.pdf`,
            file
          )
          await updateProcess(this.process.id, { report })
          this.report = []
          this.$swal({
            text: 'Se cargo el reporte y se finalizo el proceso.',
            buttons: false,
            icon: 'success',
            timer: 3000
          })
          await this.listProcess()
        }
      } catch (err) {
        console.error('Error on upload report:', err)
        this.$swal({
          text: err.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    async editProcess () {
      try {
        await updateProcess(this.process.id, { ...this.updateProcess })
        this.$swal({
          text: 'Se actualizo el proceso.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.processModal = false
        this.listProcess()
      } catch (err) {
        console.error('Error on update process:', err)
        this.$swal({
          text: err.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    async removeReport () {
      try {
        await updateProcess(this.process.id, { report: null })
        this.removeModal = false
        this.$swal({
          text: 'Se elimino el reporte del proceso.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.listProcess()
      } catch (err) {
        console.error('Error on update process:', err)
        this.$swal({
          text: err.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    async extraCost () {
      try {
        this.process.extraCost = this.process.extraCost.replace(/[^0-9.,-]/g, '')
        if (this.process.extraCost) {
          await updateProcess(this.process.id, { extraCost: parseInt(this.process.extraCost) })
          this.$swal({
            text: 'Se actualizo los viaticos para el proceso.',
            buttons: false,
            icon: 'success',
            timer: 3000
          })
        }
      } catch (err) {
        console.error('Error on update process with viatics:', err)
        this.$swal({
          text: err.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    }
  },
  computed: {
    getStatusService,
    formatDate,
    formatDateUTC,
    getProcessStatus,
    getError,
    ...mapGetters({
      user: 'getUser'
    }),
    OnlyAdmin,
    OnlyRiskAndAnalyst,
    OnlyRisk,
    IfAnalystAssigned () {
      return (service) => {
        if (this.user.rol === 'ANALYST') {
          return service.userAssigned.id === this.user.id
        } else return true
      }
    }
  }
}
</script>
<style lang="scss" scoped src="./Process.scss"></style>
