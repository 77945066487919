import axios from '../plugins/axios'

const getProcess = id => axios.get(`/process/${id}`)
const getProcesses = (companyId, status, documentId, fullName, skip, take) =>
  axios.get('/processes', {
    params: { companyId, status, documentId, fullName, skip, take }
  })
const createProcess = process => axios.post('/process', process)
const updateProcess = (processId, process) => axios.put(`/process/${processId}`, process)
const assignServiceByProcess = (serviceByProcessId, userAssigned, processId) => axios.put('/process/assign/service', { serviceByProcessId, userAssigned, processId })
const qualifyServiceByProcess = (serviceByProcessId, status, processId) => axios.put('/process/qualify/service/' + processId, { serviceByProcessId, status })

export { getProcess, getProcesses, createProcess, updateProcess, assignServiceByProcess, qualifyServiceByProcess }
