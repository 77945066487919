<template src="./ComplianceCompany.html"></template>

<script>
import TaskList from '../../../components/Forms/TaskList/TaskList'
import {
  getCompany,
  getAssignedUsers,
  assignComplianceHours,
  addUsersCompliance,
  removeUserAssignment,
  getTemplatesPlans,
  assignPlanToCompany
} from '../../../services'
import UsersCompliance from '../../../components/Forms/UsersCompliance/UsersCompliance'

export default {
  name: 'ComplianceCompany',
  components: {
    UsersCompliance,
    TaskList
  },
  data () {
    return {
      company: {},
      hours: 0,
      user: null,
      assignedUsers: [],
      compliancePlan: '',
      plans: [],
      tasks: [],
      showTaskForm: false,
      newTask: {
        activity: '',
        assignedHours: 0,
        plannedDate: new Date().toISOString().substr(0, 10),
        guide: ''
      },
      showTask: false,
      compliancePlanId: '',
      menu: false,
      editingPlan: false
    }
  },

  computed: {
    isPlanAssigned () {
      return !!this.company.planId && !this.editingPlan
    }
  },

  created () {
    this.getCompanyInfo()
    this.getAssignedUsersInfo()
    this.getPlansCompliance()
  },

  watch: {
    compliancePlan (newPlan) {
      this.updateTasks(newPlan)
      this.showTask = true
    }
  },

  methods: {
    async getCompanyInfo () {
      try {
        this.company = await getCompany(this.$route.params.id)
        this.hours = this.company.hoursCompliance
        if (this.company.compliancePlan) {
          this.compliancePlan = this.company.compliancePlan
          this.compliancePlanId = this.company.compliancePlan.id
        }
      } catch (err) {
        console.log('Error on get company:', err)
      }
    },
    async getAssignedUsersInfo () {
      try {
        const info = await getAssignedUsers(this.$route.params.id)
        this.assignedUsers = info.data
      } catch (err) {
        console.log('Error on get company:', err)
      }
    },
    async saveHoursCompliance () {
      try {
        const payload = {
          hours: Number(this.hours),
          company: this.$route.params.id
        }
        await assignComplianceHours(payload)
        this.getCompanyInfo()
        this.$swal({
          text: 'Se asignaron las horas correctamente.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
      } catch (err) {
        console.error('Error al agregar horas:', err)
      }
    },
    async saveUsersCompliance () {
      try {
        const payload = {
          user: this.user.id,
          company: this.$route.params.id
        }
        await addUsersCompliance(payload)
        this.user = null
        this.getAssignedUsersInfo()
        this.$swal({
          text: 'Se agregó el usuario correctamente.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
      } catch (error) {
        console.error('Error al agregar horas:', error)
      }
    },
    ifRemoveUser (user) {
      this.$swal({
        title: '¿Está seguro de retirar el usuario?',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then((value) => {
        if (value) {
          this.removeComplianceUser(user)
        }
      })
    },
    async removeComplianceUser (infoUser) {
      try {
        await removeUserAssignment(infoUser.id)
        this.getAssignedUsersInfo()
        this.$swal({
          text: 'Se retiro el usuario correctamente.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
      } catch (err) {
        console.error('Error on remove user compliance', err)
        this.$swal({
          text: err.message || 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    async getPlansCompliance () {
      try {
        const info = await getTemplatesPlans()
        this.plans = info
      } catch (err) {
        console.log('Error on get templates plans:', err)
      }
    },
    updateTasks (plan) {
      this.tasks = plan ? plan.tasks : []
      this.compliancePlanId = plan.id
    },
    removeTask (task) {
      this.tasks = this.tasks.filter((t) => t !== task)
    },
    addTask () {
      if (this.newTask.activity) {
        this.tasks.push({
          ...this.newTask,
          assignedHours: Number(this.newTask.assignedHours)
        })
        this.newTask.activity = ''
        this.newTask.plannedDate = new Date().toISOString().substr(0, 10)
        this.newTask.guide = ''
        this.newTask.assignedHours = 0
        this.showTaskForm = false
      } else {
        this.$swal({
          text: 'La actividad es obligatoria',
          buttons: false,
          icon: 'warning',
          timer: 3000
        })
      }
    },
    showFormTask () {
      this.showTaskForm = true
    },
    async assignPlan () {
      try {
        if (!this.assignedUsers[0]) {
          this.$swal({
            text: 'Debe asignar un usuario',
            buttons: false,
            icon: 'warning',
            timer: 3000
          })
        } else {
          const payload = {
            companyId: this.$route.params.id,
            compliancePlanId: this.compliancePlanId,
            userId: this.assignedUsers[0].userId.id,
            tasks: this.tasks.map((task) => ({
              activity: task.activity,
              assignedHours: Number(task.assignedHours) * 60,
              guide: task.guide,
              plannedDate: new Date(task.plannedDate)
            }))
          }
          await assignPlanToCompany(payload)
          await this.getCompanyInfo()
          this.showTask = false
          this.editingPlan = false
          this.$swal({
            text: 'Plan asignado correctamente.',
            buttons: false,
            icon: 'success',
            timer: 3000
          })
        }
      } catch (error) {
        console.log('Error on assign Plan:', error)
        this.$swal({
          text: error.message || 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    cancelTask () {
      this.newTask.activity = ''
      this.newTask.assignedHours = 0
      this.newTask.plannedDate = new Date().toISOString().substr(0, 10)
      this.newTask.guide = ''
      this.showTaskForm = false
    },
    togglePlanAssignment () {
      this.editingPlan = !this.editingPlan
    }
  }
}
</script>

<style lang="scss" src="./ComplianceCompany.scss" scoped></style>
