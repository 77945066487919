<template src="./ProgressProcess.html"></template>

<script>
import { SERVICE_STATUS } from '../../utils/constants'
import { formatDateUTC, getStatusService } from '../../utils/computeds'

export default {
  name: 'ProgressProcess',
  props: {
    servicesByProcess: Array,
    createdAt: String,
    promiseDate: String,
    small: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    progress: 0
  }),
  computed: {
    orderServices: function () {
      if (this.servicesByProcess) {
        const servicesByProcess = JSON.parse(
          JSON.stringify(this.servicesByProcess)
        )
        return servicesByProcess.sort((a, b) =>
          b.status === SERVICE_STATUS.APPROVED ||
          b.status === SERVICE_STATUS.NOT_APPROVED ||
          b.status === SERVICE_STATUS.CANCELLED
            ? 1
            : -1
        )
      }
      return []
    },
    getStatusService,
    formatDateUTC,
    getProgress () {
      if (this.servicesByProcess) {
        var p = 100 / this.servicesByProcess.length
        return this.servicesByProcess.reduce(
          (percentage, service) =>
            percentage +
            (service.status === SERVICE_STATUS.PENDING || service.status === SERVICE_STATUS.ASSIGNED
              ? 0
              : p),
          0
        )
      }
      return 0
    }
  }
}
</script>
<style lang="scss" scoped src="./ProgressProcess.scss"></style>
