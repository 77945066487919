<template src="./Service.html"></template>
<script>
import { deleteServiceByCompany, updateServiceByCompany } from '../../services'

export default {
  name: 'Service',
  props: ['services', 'service', 'isGroup'],
  data: () => ({
    edit: false,
    editService: {}
  }),
  methods: {
    editActive (service) {
      this.editService = service
      this.edit = true
    },
    cancel () {
      this.service = this.editService
      this.edit = false
    },
    ifSaveService (service) {
      this.$swal({
        title: '¿Está seguro de actualizar el servicio?',
        icon: 'warning',
        buttons: true
      }).then((value) => {
        if (value) this.save(service)
      })
    },
    async save (service) {
      try {
        const updateService = {
          serviceId: service.service.id,
          ans: service.ans,
          value: service.value,
          order: service.order
        }
        await updateServiceByCompany(service.id, updateService)
        this.edit = false
        this.$swal({
          text: 'Se actualizo el servicio.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        await this.$emit('updateList')
      } catch (err) {
        console.error('Update service by company:', err)
        this.$swal({
          text: 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    ifDeleteService (serviceId) {
      this.$swal({
        title: '¿Está seguro de eliminar el servicio?',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then((value) => {
        if (value) this.remove(serviceId)
      })
    },
    async remove (serviceId) {
      try {
        this.$swal({
          text: 'Se eliminó el servicio.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        await deleteServiceByCompany(serviceId)
        await this.$emit('updateList')
      } catch (err) {
        console.error('Remove service by company:', err)
        this.$swal({
          text: 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    }
  }
}
</script>
