import Vue from 'vue'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth, onAuthStateChanged, getIdToken } from 'firebase/auth'
import { getUser, logOff } from '../services'
import store from '../store'
import axios from '../plugins/axios'
import * as Sentry from '@sentry/vue'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  measurementId: process.env.VUE_APP_measurementId,
  appId: process.env.VUE_APP_appId
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

const firebaseAuth = getAuth()
firebaseAuth.languageCode = 'es'

onAuthStateChanged(firebaseAuth, async (user) => {
  if (user) {
    try {
      Sentry.setUser({
        id: String(user.uid),
        email: user.email
      })

      axios.defaults.headers.common.Authorization = `Bearer ${user.accessToken}`
      const userActive = await getUser(user.uid)
      userActive.picture = user.photoURL || null
      store.dispatch('pushUser', userActive)
      localStorage.setItem('auth', true)

      setInterval(async () => {
        console.log('Refresh user token')
        const token = await getIdToken(user, true)
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
      }, 3000000)

      if (window.location.pathname === '/login') window.location.pathname = '/'
    } catch (error) {
      console.error('Error with sesion', error)

      Vue.prototype.$swal({
        text: error?.message || 'Ocurrio un error, intenta mas tarde.',
        buttons: false,
        icon: 'error',
        timer: 4000
      })
      await logOff()
      localStorage.removeItem('auth')
      if (window.location.hash !== '/login') window.location.hash = '/login'
    }
  } else {
    await logOff()
    localStorage.removeItem('auth')
    store.dispatch('pushUser', null)

    Sentry.setUser(null)

    if (window.location.pathname !== '/login') window.location.pathname = '/login'
  }
})

export {
  app,
  firebaseAuth,
  db
}
