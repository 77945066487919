import axios from '@/plugins/axios'

const getCompanies = search => axios.get('/companies', { params: { ...search } })
const getCompany = (id) => axios.get(`/company/${id}`)
const createCompany = company => axios.post('/company', company)
const updateCompany = company => axios.put('/company', company)
const deleteCompany = id => axios.delete('/company', { data: { id } })

export {
  createCompany,
  getCompanies,
  getCompany,
  updateCompany,
  deleteCompany
}
