<template src="./Service.html"></template>

<script>
import { required } from 'vuelidate/lib/validators'
import { createService, updateService } from '../../../services'

export default {
  name: 'Services',
  data: () => ({
    service: {},
    update: false
  }),
  validations () {
    return {
      service: {
        name: { required },
        cost: { required },
        icon: { required }
      }
    }
  },
  created () {
    this.isUpdateServices()
  },
  methods: {
    async saveService () {
      try {
        if (this.$v.$invalid) {
          return false
        } else {
          await createService(this.service)
          this.reset()
          this.$swal({
            text: 'Se agregó el servicio.',
            buttons: false,
            icon: 'success',
            timer: 3000
          })
        }
      } catch (err) {
        console.error('Error on save service', err)
        this.$swal({
          text: err.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    reset () {
      this.service = {}
      this.update = false
    },
    updated (service) {
      this.service = service
      this.update = true
    },
    async changeService (service) {
      try {
        await updateService(service)
        this.$swal({
          text: 'Se Actualizo el servicio.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.reset()
        this.$router.replace({ name: 'Lista Servicios' })
      } catch (err) {
        console.error('Error on update service', err)
        this.$swal({
          text: err.message ?? 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    isUpdateServices () {
      if (this.$route.params.updateService) {
        this.service = this.$route.params.updateService
        this.update = true
      }
    }
  }
}
</script>
