<template src="./CreateUser.html"></template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import {
  createUser,
  updateUser,
  getRoleName,
  getRoles
} from '../../../services'
import Companies from '../../../components/Forms/Companies/Companies'
import TypeDocument from '../../../components/Forms/TypeDocument/TypeDocument'
import moment from 'moment'

export default {
  name: 'Users',
  components: { Companies, TypeDocument },
  data: () => ({
    user: {
      active: true
    },
    roles: [],
    update: false,
    showPassword: false,
    rules: {
      min: (v) => v?.length >= 6 || 'Minimo 6 caracteres'
    },
    dateModal: false
  }),
  validations () {
    return {
      user: {
        name: { required },
        lastName: { required },
        email: { email, required },
        rol: { required },
        company: { required },
        password: { minLength: minLength(6) },
        city: { required },
        phoneNumber: { required },
        birthdate: { required }
      }
    }
  },
  created () {
    this.getListRoles()
    this.isUpdateUser()
  },
  methods: {
    isUpdateUser () {
      if (this.$route.params.updateUser) {
        this.user = this.$route.params.updateUser
        this.user.birthdate = moment(this.user.birthdate).format('YYYY-MM-DD')
        this.update = true
      }
    },
    reset () {
      this.user = {
        active: true
      }
      this.update = false
    },
    async saveUser () {
      try {
        if (this.$v.$invalid) {
          return false
        } else {
          await createUser(this.user)
          this.reset()
          this.$swal({
            text: 'Se creo el usuario.',
            buttons: false,
            icon: 'success',
            timer: 3000
          })
        }
      } catch (err) {
        console.error('Error on save user', err)
        this.$swal({
          text: 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    updated (user) {
      this.user = JSON.parse(JSON.stringify(user))
      this.update = true
    },
    async changeUser (user) {
      try {
        await updateUser(user)
        this.$swal({
          text: 'Se Actualizo el usuario.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.reset()
      } catch (err) {
        console.error('Error on update user', err)
        this.$swal({
          text: 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    async getListRoles () {
      try {
        const roles = await getRoles()
        this.roles = roles.map((rol) => ({
          id: rol,
          name: getRoleName(rol)
        }))
      } catch (err) {
        console.log('List getRoles:', err)
      }
    }
  }
}
</script>
