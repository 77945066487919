<template src="./Status.html"></template>

<script>
import { processStatusVal } from '../../../utils/constants'

export default {
  name: 'Status',
  props: {
    value: String,
    isDisabled: Boolean
  },
  model: {
    prop: 'value',
    event: 'update'
  },
  data: () => ({
    status: processStatusVal
  })
}
</script>
