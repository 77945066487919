<template src="./ListUsers.html"></template>
<script>
import {
  getAllUsers,
  deleteUser,
  getRoleName,
  getRoles
} from '../../../services'
import Companies from '../../../components/Forms/Companies/Companies'
import { requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { getRolName } from '../../../utils/computeds'
import { OnlyRisk, OnlyAdmin } from '../../../utils/roles'

export default {
  name: 'ListUsers',
  components: { Companies },
  data: () => ({
    users: [],
    roles: [],
    search: {},
    take: 20,
    skip: 0,
    disabled: false
  }),
  validations () {
    const searchIfEmpty = () => Object.keys(this.search).length === 0
    return {
      search: {
        email: { required: requiredIf(searchIfEmpty()) },
        rol: { required: requiredIf(searchIfEmpty()) },
        company: { required: requiredIf(searchIfEmpty()) }
      }
    }
  },
  created () {
    this.getListRoles()
    this.searchUsers()
  },
  methods: {
    async getListRoles () {
      try {
        const roles = await getRoles()
        this.roles = roles.map((rol) => ({
          id: rol,
          name: getRoleName(rol)
        }))
      } catch (err) {
        console.log('List getRoles:', err)
      }
    },
    async searchUsers (addUsers) {
      try {
        const users = await getAllUsers({
          take: this.take,
          skip: this.skip,
          ...this.search
        })

        if (!addUsers) this.users = users
        else this.users.push(...users)

        if (this.skip + this.take > this.users.length) this.disabled = true
        else this.disabled = false

        this.search = {}
      } catch (err) {
        console.log('List Users:', err)
      }
    },
    ifDeleteUser (user) {
      this.$swal({
        title: '¿Está seguro de eliminar el usuario?',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then((value) => {
        if (value) {
          this.removeUser(user)
        }
      })
    },
    async removeUser (user) {
      try {
        await deleteUser(user.id)
        this.$swal({
          text: 'Se eliminó el usuario.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.users = []
      } catch (err) {
        console.error('Error on remove user', err)
        this.$swal({
          text: err.message || 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    async moreUsers () {
      this.skip += this.take
      await this.searchUsers(true)
    }
  },
  computed: {
    ...mapGetters({
      userLogged: 'getUser'
    }),
    getRolName,
    OnlyRisk,
    OnlyAdmin
  }
}
</script>
