const ROL = Object.freeze({
  ADMIN: 'ADMIN',
  RISK_SHIELD: 'RISK_SHIELD',
  ANALYST: 'ANALYST',
  COMPANY: 'COMPANY',
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  COMPLIANCE: 'COMPLIANCE',
  COMPLIANCE_ADMIN: 'COMPLIANCE_ADMIN'
})

const ROLES = Object.freeze({
  [ROL.ADMIN]: 'Administrador',
  [ROL.RISK_SHIELD]: 'Risk Shield',
  [ROL.ANALYST]: 'Analista',
  [ROL.COMPANY]: 'Atracción Talento',
  [ROL.COMPANY_ADMIN]: 'Administrador de Atracción Talento',
  [ROL.COMPLIANCE]: 'Cumplimiento',
  [ROL.COMPLIANCE_ADMIN]: 'Administrador de cumplimiento'
})

const getRoleName = (rol) => ROLES[rol]

export {
  ROL,
  ROLES,
  getRoleName
}
