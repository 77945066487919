<template src="./Indicators.html"></template>

<script>
import { mapGetters } from 'vuex'
import {
  getComplianceByCompany,
  getWeeklyUser,
  getMonthlyCompliance,
  getAnnualCompliance
} from '../../../services'
import { formatToTwoDecimals } from '../../../utils/computeds'

export default {
  name: 'Indicators',
  data () {
    return {
      companiesData: [],
      weeklyData: {
        occupation: 0,
        currentWeek: 0,
        previousWeek: 0,
        twoWeeksAgo: 0,
        percentage: 0
      },
      annualPlan: {
        planned: 0,
        executed: 0,
        compliance: 0,
        added: 0
      },
      currentWeekNumber: 0,
      previousWeekNumber: 0,
      twoWeeksAgoNumber: 0,
      monthlyCompliance: 0,
      sortOrder: 'asc'
    }
  },
  created () {
    this.fetchCompaniesData()
    this.loadWeeklyData()
    this.loadMonthlyCompliance()
    this.loadAnnualCompliance()
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    })
  },
  methods: {
    async fetchCompaniesData () {
      try {
        this.companiesData = await getComplianceByCompany()
      } catch (error) {
        console.error(error)
      }
    },
    toggleSortOrder (field) {
      if (this.sortField === field) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortField = field
        this.sortOrder = 'asc'
      }
      this.sortCompaniesData()
    },
    sortCompaniesData () {
      this.companiesData.sort((a, b) => {
        const valueA = a[this.sortField]
        const valueB = b[this.sortField]

        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return this.sortOrder === 'asc' ? valueA - valueB : valueB - valueA
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return this.sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)
        }

        return 0
      })
    },
    async loadWeeklyData () {
      try {
        const response = await getWeeklyUser(this.user.id)
        const {
          occupation,
          currentWeek,
          previousWeek,
          twoWeeksAgo,
          currentWeekNumber,
          previousWeekNumber,
          twoWeeksAgoNumber,
          percentage
        } = response

        this.weeklyData = {
          occupation,
          currentWeek,
          previousWeek,
          twoWeeksAgo,
          percentage
        }

        this.currentWeekNumber = currentWeekNumber
        this.previousWeekNumber = previousWeekNumber
        this.twoWeeksAgoNumber = twoWeeksAgoNumber
      } catch (error) {
        console.error('Error fetching weekly data:', error)
      }
    },
    async loadMonthlyCompliance () {
      try {
        const response = await getMonthlyCompliance(this.user.id)
        this.monthlyCompliance = response.monthlyCompliance
      } catch (error) {
        console.error('Error fetching monthly compliance:', error)
      }
    },
    async loadAnnualCompliance () {
      try {
        const response = await getAnnualCompliance(this.user.id)
        const { planned, executed, compliance, added } = response
        this.annualPlan = { planned, executed, compliance, added }
      } catch (error) {
        console.error('Error fetching annual compliance:', error)
      }
    },
    formatToTwoDecimals
  }
}
</script>

<style lang="scss" src="../ComplianceByUser/ComplianceByUser.scss" scoped></style>
