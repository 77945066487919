export default {
  state: {
    loading: false
  },
  getters: {
    getLoading: state => state.loading
  },
  mutations: {
    setLoading (state, value) {
      state.loading = value
      if (!value) {
        state.percentage = 0
      }
    }
  },
  actions: {
    fetchLoading ({ commit }, loading) {
      commit('setLoading', loading)
    }
  }
}
