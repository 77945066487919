<template src="./Companies.html"></template>

<script>
import { getCompanies } from '../../../services'

export default {
  name: 'Companies',
  props: {
    value: [String, Object],
    change: Function,
    focus: Boolean,
    isRequired: Boolean,
    returnObject: {
      type: Boolean,
      default: false
    },
    compliance: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'value',
    event: 'update'
  },
  data: () => ({
    companies: [],
    rules: []
  }),
  created () {
    this.listCompany()
  },
  mounted () {
    if (this.focus) this.$refs.companies.$refs.input.focus()
    if (this.isRequired) this.rules = [(val) => !!val]
  },
  methods: {
    async listCompany () {
      try {
        this.companies = await getCompanies()
        if (!this.compliance && this.companies.length === 1) {
          this.$emit('update', this.companies[0].id)
          this.changeValue(this.companies[0].id)
        }
      } catch (err) {
        console.log('List companies:', err)
      }
    },
    changeValue (e) {
      if (this.change) this.change(e)
    }
  }
}
</script>
