<template src="./TaskList.html"></template>

<script>
import { formatDate } from '../../../utils/computeds'

export default {
  name: 'TaskList',
  props: {
    tasks: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    formatDate
  }
}
</script>

<style lang="scss" src="./TaskList.scss" scoped></style>
