<template src="./CompanyServices.html"></template>

<script>
import {
  getCompany,
  getServices,
  getServiceByCompany,
  createServiceByCompany,
  updateGroupByCompany
} from '../../../services'
import Service from '../../../components/Service/Service'
import { isNumber, calcANS } from '../../../utils'
import { required, minValue } from 'vuelidate/lib/validators'

export default {
  name: 'CompanyServices',
  components: {
    Service
  },
  data: () => ({
    company: {},
    services: [],
    servicesByCompany: [],
    groupServicesByCompany: [],
    selectService: {},
    showServiceForm: false,
    showGroupServiceForm: false,
    newService: {},
    newServices: [],
    nameGroup: '',
    totalGroup: 0,
    costGroup: 0,
    group: {
      id: '',
      open: false,
      name: '',
      total: 0,
      cost: 0,
      ans: 0
    }
  }),
  validations () {
    const newService = {
      ans: { required },
      value: { required, minValue: minValue(this.selectService.cost) }
    }
    const newServices = {
      required,
      $each: {
        listServices: { id: { required } },
        ans: { required },
        order: { required },
        value: {
          required,
          minValue: (value, item) => value >= item.listServices.cost
        }
      }
    }

    if (this.showServiceForm) {
      return {
        newService
      }
    }

    if (this.showGroupServiceForm) {
      return {
        nameGroup: { required },
        totalGroup: { required, minValue: minValue(this.costGroup) },
        newServices
      }
    }
  },
  created () {
    this.getCompanyInfo()
    this.getCompanyServices()
    this.listServices()
    this.newServices.push({
      listServices: this.services
    })
  },
  methods: {
    async getCompanyInfo () {
      try {
        this.company = await getCompany(this.$route.params.id)
      } catch (err) {
        console.log('Error on get company:', err)
      }
    },
    async getCompanyServices () {
      try {
        const [servicesByCompany, ...groupServicesByCompany] = await getServiceByCompany(
          this.$route.params.id
        )
        this.servicesByCompany = servicesByCompany
        this.groupServicesByCompany = groupServicesByCompany
      } catch (err) {
        console.log('Error on list company services:', err)
      }
    },
    async listServices () {
      try {
        this.services = await getServices()
      } catch (err) {
        console.log('Error on list services:', err)
      }
    },
    async createOneService () {
      try {
        const service = {
          isGroup: false,
          companyId: this.$route.params.id,
          service: {
            serviceId: this.selectService.id,
            value: this.newService.value,
            ans: this.newService.ans
          }
        }
        await createServiceByCompany(service)
        this.$swal({
          text: 'Se agregó el servicio.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })
        this.newService = {}
        // this.services = await getServices();
        await this.getCompanyServices()
        this.showServiceForm = false
      } catch (err) {
        console.error('Create service by company:', err)
        this.$swal({
          text: 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    async createGroup () {
      try {
        const services = this.newServices.map((service) => ({
          serviceId: service.listServices.id,
          value: service.value,
          ans: service.ans,
          order: service.order
        }))

        await createServiceByCompany({
          isGroup: true,
          companyId: this.$route.params.id,
          name: this.nameGroup,
          total: this.totalGroup,
          services
        })
        this.$swal({
          text: 'Se agregó el grupo de servicios.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })

        this.services = await getServices()
        await this.getCompanyServices()
        this.nameGroup = ''
        this.newServices = [
          {
            listServices: this.services
          }
        ]
        this.showGroupServiceForm = false
      } catch (err) {
        console.error('Create service by company:', err)
        this.$swal({
          text: 'Ocurrio un error, intenta mas tarde.',
          buttons: false,
          icon: 'error',
          timer: 3000
        })
      }
    },
    isNumber,
    addService () {
      this.newServices.push({
        listServices: this.services
      })
    },
    removeService (index) {
      this.newServices = this.newServices.filter((s, i) => i !== index)
    },
    assignGroup (groups) {
      this.group.open = !this.group.open
      if (this.group.open) {
        this.group.id = groups[0].groupId
        this.group.name = groups[0].name
        this.group.total = groups[0].total
        this.group.cost = groups.reduce((sum, group) => (sum += group.service.cost), 0)
        this.group.ans = calcANS(groups)
      } else {
        this.group.id = ''
        this.group.name = ''
        this.group.total = 0
        this.group.cost = 0
        this.group.ans = 0
      }
    },
    async updateGroup () {
      try {
        await updateGroupByCompany(this.group.id, {
          total: this.group.total,
          name: this.group.name
        })
        this.$swal({
          text: 'Se actualizo el grupo de servicios.',
          buttons: false,
          icon: 'success',
          timer: 3000
        })

        await this.getCompanyServices()
      } catch (error) {
        console.error('ERROR on update group =>', error)
      }
    }
  },
  computed: {
    groupCost () {
      const total = this.newServices.reduce((total, service) => {
        total += service.listServices.cost || 0
        return total
      }, 0)
      this.costGroup = total
      return total
    },
    groupANSTotal () {
      return calcANS(this.newServices)
    }
  }
}
</script>
<style lang="scss" src="./CompanyServices.scss" scoped></style>
