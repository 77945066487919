export default {
  state: {
    title: '',
    showWhatsapp: true
  },
  getters: {
    getTitle: state => state.title,
    getWhatsapp: state => state.showWhatsapp
  },
  mutations: {
    setTitle (state, value) {
      state.title = value
    },
    setWhatsapp (state, value) {
      state.showWhatsapp = value
    }
  },
  actions: {
    fetchTitle ({ commit }, title) {
      commit('setTitle', title)
    },
    fetchWhatsapp ({ commit }, show) {
      commit('setWhatsapp', show)
    }
  }
}
